import Swiper from 'swiper';

$(document).ready(() => {
  // initialize swiper when document ready
  // eslint-disable-next-line no-new
  new Swiper('.swiper-container', {
    // Optional parameters
    centeredSlides: false,
    slidesPerView: 1,
    spaceBetween: 15,
    loop: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  });
});
