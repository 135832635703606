// vendors
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';

// this makes jquery work (I think)
window.jQuery = $;
window.$ = $;

// invidual js files
import './home';

// styles
import '../scss/main.scss';
